import http from '@flowHttp'

// 流程授权
export function workFlowLogin() {
  const params = {
    appId: 'silverdawn-workflow-server',
    secretKey: 'bh7zWGOwszppsaMJa4ly7DFo2Bz4OcHo',
  }

  return http.get('/oauth2/token/get', params)
}

// 查看我发起流程
export function getApplyList(data) {
  return http.post('/workspace/process/applyList', data)
}

// 查看我将要审批的
export function getApprovalList(data) {
  return http.post('/workspace/process/toDoList', data)
}

// 获取流程名称
export function getProcessName(data) {
  return http.get('/admin/form/group', data)
}

// 我收到的流程
export function getDoneList(data) {
  return http.post('/workspace/process/doneList', data)
}

// 撤回流程
export function revokeProcess(data) {
  return http.post('/workspace/revoke', data)
}

// 催办
export function remind(data) {
  return http.post('/workspace/remind', data)
}

// 获取流程详情
export function getProcessDetail(data) {
  return http.post('/workspace/process/instanceInfo', data)
}

// 不同意
export function refuseFlow(data) {
  return http.post('/workspace/refuse', data)
}

// 同意
export function agreeFlow(data) {
  return http.post('/workspace/agree', data)
}
