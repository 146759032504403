import axios from 'axios'

const envMap = {
  development: 'http://172.16.24.200:9998',

  // development: 'http://172.16.25.99:9998',
  production: 'https://gateway.xiaowutube.com/flowapi',
  buildTest: 'http://172.16.24.200:9998',
  buildUat: 'http://gateway.xiaowukeji.cn/flowapi',
}
const serviceUrl = envMap[process.env.NODE_ENV]

const axiosConfig = {
  baseURL: serviceUrl,
}

class RequestHttp {
  service

  constructor(configs) {
    this.service = axios.create(configs)

    this.service.interceptors.request.use(
      conf => {
        const token = localStorage.getItem('flowToken')
        if (token) conf.headers['ACCESS-TOKEN'] = token

        return conf
      },
      error => Promise.reject(error),
    )

    this.service.interceptors.response.use(
      response => {
        if (response.data.status === 401) {
          localStorage.removeItem('flowToken')
          window.location.href = '/ssoLogin'
        }
        if (response.data && response.data.status && response.data.status !== 200) {
          return Promise.reject(response.data.message)
        }

        return response.data
      },
      error => {
        /* 删除"系统内部异常"字样 */
        // eslint-disable-next-line no-param-reassign,no-unused-expressions
        error.response.data.message?.includes('系统内部异常：') && (error.response.data.message = error.response.data.message.slice(7))

        return Promise.reject(error)
      },
    )
  }

  post(url, params, _config = {}) {
    return this.service.post(url, params, _config)
  }

  get(url, params = {}, _config = {}) {
    return this.service.get(url, { params, ..._config }, { ..._config })
  }

  put(url, params, _config = {}) {
    return this.service.put(url, params, _config)
  }
}

export default new RequestHttp(axiosConfig)
